import React, { useEffect, useState } from 'react';
import { LeftArrow } from '../../constants/svgs';

const PdfViewer = ({ data, setShowModal }) => {
    const [showArrow, setshowArrow] = useState(false)
    console.log("PdfViewer ===", data)
    const [docs, setDocs] = useState([]);

    useEffect(() => {
        setDocs([{ uri: data.file_url }]);
        setTimeout(() => {
            setshowArrow(true)
        }, 1000);

    }, [data]);

    const onError = (e) => {
        console.error('Error in FileViewer:', e);
    };

    const getIframeSrc = (fileUrl) => {
        const fileExtension = fileUrl.split('.').pop().toLowerCase();
        if (fileExtension === 'pdf') {
            return fileUrl;
        } else if (fileExtension === 'docx') {
            return `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(fileUrl)}&wdHideHeaders=false`;
        } else {
            return null;
        }
    };

    const iframeSrc = getIframeSrc(data.file_url);

    if (!iframeSrc) {
        return <div>Unsupported file type.</div>;
    }

    const handleBackfucntion = () => {
        setShowModal(false)
    }
    return (
        <div className='general-file-viewer'>
            {showArrow && <div className="leftArrow" onClick={handleBackfucntion}>
                <LeftArrow />
            </div>}
            <iframe
                src={iframeSrc}
                style={{ width: "100%", height: "calc(100vh - 20px)" }}
                frameBorder="0"
                onError={onError}
            />
        </div>
    );
};

export default PdfViewer;
